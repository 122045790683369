/**
 * Created by Nikolay on 5/5/2016.
 */


( function( $ ) {

    /* JS to slide down on home page when the arrow is clicked */
    $('.arrow-down').on( 'click', function(){

        $('html, body').animate({
            scrollTop: $("#main").offset().top - 80
        }, 1000);

    } );

} )(jQuery);

/*script for language switcher*/

function switchLocale(language){
    var path = window.location.pathname;
    var newPath = path;
    var pathArray = path.split('/');
    console.log(pathArray);
    switch(language) {
        case 'bg':
            if(pathArray[1] == 'en'){
                pathArray[1] = 'bg';
                newPath = pathArray.join('/');
                break;
            }

            break;
        case 'en':
            if(pathArray[1] == 'bg'){
                pathArray[1] = 'en';
                newPath = pathArray.join('/');
                break;
            }

            break;
        default:

    }

    window.location.href = newPath;
}

